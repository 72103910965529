import { render, staticRenderFns } from "./partners.vue?vue&type=template&id=2a2480ec&scoped=true"
import script from "./partners.vue?vue&type=script&lang=js"
export * from "./partners.vue?vue&type=script&lang=js"
import style0 from "../style.scss?vue&type=style&index=0&id=2a2480ec&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a2480ec",
  null
  
)

export default component.exports