<template>
  <div>
    <div class="page">
      <div class="history">
        <h1 class="title main-title">О нас</h1>
        <div class="subtitle">DATAFORCE — мощная и функциональная  аналитическая платформа повышения эффективности маркетинга для eСommerce. Делаем принятие решений для e-commerce быстрыми, на основании проверенной информации и в одном интерфейсе.</div>
        <div class="info">
          <div class="text">
            <div class="title">Наша история</div>
            <div class="subtext">Компания создана в 2014 г. и занимается исследованиями и разработками в области решений для автоматического анализа сайтов интернет-ритейлеров для максимизации продаж и роста эффективности.</div>
          </div>
          <picture>
            <source
                srcset="@/assets/img/landing/team/stas-main_320.webp"
                type="image/webp"
                media="(max-width: 320px)"
            />
            <source
                :srcset="require('@/assets/img/landing/team/stas-main_320.jpg')"
                type="image/jpg"
                media="(max-width: 320px)"
            />
            <source
                :srcset="require('@/assets/img/landing/team/stas-main_480.webp')"
                type="image/webp"
                media="(max-width: 480px)"
            />
            <source
                :srcset="require('@/assets/img/landing/team/stas-main_480.jpg')"
                type="image/jpg"
                media="(max-width: 480px)"
            />
            <source
                :srcset="require('@/assets/img/landing/team/stas-main_768.webp')"
                type="image/webp"
                media="(max-width: 768px)"
            />
            <source
                :srcset="require('@/assets/img/landing/team/stas-main_768.jpg')"
                type="image/jpg"
                media="(max-width: 768px)"
            />
            <source
                :srcset="require('@/assets/img/landing/team/stas-main.webp')"
                type="image/webp"
            />
            <img
                class="main"
                src="@/assets/img/landing/team/stas.png" alt="Стас Поломар."
                loading="lazy"
            />
          </picture>
          <img
              class="dots1"
              src="@/assets/img/landing/team/dots.svg"
          />
          <img
              class="dots2"
              src="@/assets/img/landing/team/dots.svg"
          />
        </div>
      </div>

      <div class="today">
        <div class="title">DATAFORCE сегодня это:</div>
        <div class="image">
          <img src="@/assets/img/landing/team/today.png" alt="DATAFORCE сегодня это:">
        </div>
      </div>
    </div>

    <div class="team">
      <div class="page">
        <div class="wrapper">
          <div class="header">
            <div class="title">Команда</div>
          </div>
          <div class="body">
            <div
                v-for="(item, i) in team"
                :key="i"
                class="person"
            >
              <div class="position">{{ item.position.name }}</div>
              <img :src="item.profile.avatar_url" class="img"/>
              <div class="name">{{ item.full_name[0] }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page">
      <div class="developing">
        <div class="title">Разработка ПО DATAFORCE и меры поддержки</div>
        <div class="subtitle">ПО для ЭВМ:</div>
        <ul class="list">
          <li class="list__item">
            «Платформа построения цифровых двойников сайтов онлайн-ритейла и повышения их эффективности с целью роста онлайн-продаж» (свидетельство № 2022681452 от 04.112022);
          </li>
          <li class="list__item">
            «Модуль синтаксического анализа информационного наполнения интернет-магазинов» (свидетельство № 2021613555 от 10.03.2021).
          </li>
        </ul>

        <div class="subtitle" id="fasie">Реестр ПО РФ:</div>
        <ul class="list">
          <li class="list__item">
            «Платформа для повышения эффективности онлайн маркетинга с помощью построения цифровых двойников ритейла с решениями на основе искусственного интеллекта DATAFORCE», № 266595 от 30.11.2022 (<a href="https://reestr.digital.gov.ru/reestr/1199166/" target="_blank">https://reestr.digital.gov.ru/reestr/1199166/</a>).
          </li>
        </ul>

        <div class="subtitle">Успешно реализованные меры поддержки:</div>
        <ul class="list">
          <li class="list__item">
            Статус резидента Сколково с ноября 2019 (№ ОРН 1122878);
          </li>
          <li class="list__item">
            Реализация проекта НИОКР «Разработка платформы построения цифровых двойников сайтов онлайн-ритейла и повышения их эффективности с целью роста онлайн-продаж» при поддержке Фонда содействия инновациям  №17ГРЦЭИИС12-D7/72511 от 22.12.2021.
          </li>
        </ul>
      </div>

      <div class="developing">
        <div class="title">Сертификаты</div>
        <ul class="certificate__list">
          <li><img src="@/assets/img/landing/team/certificate_1.jpeg"/></li>
          <li><img src="@/assets/img/landing/team/certificate_2.jpeg"/></li>
        </ul>
      </div>
    </div>

    <div class="mb-section">
			<v-partners/>
		</div>
  </div>
</template>

<script>
import partners from '../components/partners.vue'

export default {
  name: 'team',

  metaInfo() {
    return {
      title: 'DATAFORCE — команда нашей платформы',
      meta: [
        {
          name: 'description',
          content: 'Команда нашей аналитической платформы DATAFORCE, сертификаты из реестра ПО РФ, наши постоянные партнеры'
        }
      ]
    }
  },

  components: {
    'v-partners': partners
  },

  data () {
    return {
      team: [
        {
          id: 2,
          name: 'Стас',
          surname: null,
          position_id: 2,
          full_name: ['Стас'],
          position: {id: 2, name: 'CEO'},
          profile: {
            people_id: 2,
            avatar_id: 71,
            avatar_url: 'https://static.seowork.ru/team/avatar/c81e728d9d4c2f636f067f89cc14862c/e2c420d928d4bf8ce0ff2ec19b371514.img'
          }
        },
        {
          id: 28,
          name: 'Александр',
          surname: null,
          position_id: 40,
          full_name: ['Александр'],
          position: {id: 40, name: 'Product гуру'},
          profile: {
            people_id: 28,
            avatar_id: 82,
            avatar_url: 'https://static.seowork.ru/team/avatar/33e75ff09dd601bbe69f351039152189/9778d5d219c5080b9a6a17bef029331c.img'
          }
        },
        {
          id: 1,
          name: 'Игорь',
          surname: null,
          position_id: 7,
          full_name: ['Игорь'],
          position: {id: 7, name: 'Дизайн-директор'},
          profile: {
            people_id: 1,
            avatar_id: 72,
            avatar_url: 'https://static.seowork.ru/team/avatar/c4ca4238a0b923820dcc509a6f75849b/32bb90e8976aab5298d5da10fe66f21d.img'
          }
        },
        {
          id: 22,
          name: 'Алексей',
          surname: null,
          position_id: 16,
          full_name: ['Алексей'],
          position: {id: 16, name: 'Back мастер'},
          profile: {
            people_id: 22,
            avatar_id: 75,
            avatar_url: 'https://static.seowork.ru/team/avatar/b6d767d2f8ed5d21a44b0e5886680cb9/d09bf41544a3365a46c9077ebb5e35c3.img'
          }
        },
        {
          id: 14,
          name: 'Константин',
          surname: null,
          position_id: 45,
          full_name: ['Константин'],
          position: {id: 45, name: 'Аналитик продукта'},
          profile: {
            people_id: 14,
            avatar_id: 100,
            avatar_url: 'https://static.seowork.ru/team/avatar/aab3238922bcc25a6f606eb525ffdc56/f899139df5e1059396431415e770c6dd.img'
          }
        },
        {
          id: 16,
          name: 'Евгений',
          surname: null,
          position_id: 16,
          full_name: ['Евгений'],
          position: {id: 16, name: 'Back мастер'},
          profile: {
            people_id: 16,
            avatar_id: 61,
            avatar_url: 'https://static.seowork.ru/team/avatar/c74d97b01eae257e44aa9d5bade97baf/7f39f8317fbdb1988ef4c628eba02591.img'
          }
        },
        {
          id: 33,
          name: 'Вячеслав',
          surname: null,
          position_id: 28,
          full_name: ['Вячеслав'],
          position: {id: 28, name: 'QA инженер'},
          profile: {
            people_id: 33,
            avatar_id: 132,
            avatar_url: 'https://static.seowork.ru/team/avatar/182be0c5cdcd5072bb1864cdee4d3d6e/65ded5353c5ee48d0b7d48c591b8f430.img'
          }
        },
        {
          id: 65,
          name: 'Егор',
          surname: null,
          position_id: 6,
          full_name: ['Егор'],
          position: {id: 6, name: 'Front мастер'},
          profile: {
            people_id: 65,
            avatar_id: 128,
            avatar_url: 'https://static.seowork.ru/team/avatar/fc490ca45c00b1249bbe3554a4fdf6fb/76dc611d6ebaafc66cc0879c71b5db5c.img'
          }
        },
        {
          id: 63,
          name: 'Анна',
          surname: null,
          position_id: 5,
          full_name: ['Анна'],
          position: {id: 5, name: 'Back'},
          profile: {
            people_id: 63,
            avatar_id: 125,
            avatar_url: 'https://static.seowork.ru/team/avatar/03afdbd66e7929b125f8597834fa83a4/3def184ad8f4755ff269862ea77393dd.img'
          }
        },
        {
          id: 42,
          name: 'Дарья',
          surname: null,
          position_id: 6,
          full_name: ['Дарья'],
          position: {id: 6, name: 'Front мастер'},
          profile: {
            people_id: 42,
            avatar_id: 101,
            avatar_url: 'https://static.seowork.ru/team/avatar/a1d0c6e83f027327d8461063f4ac58a6/38b3eff8baf56627478ec76a704e9b52.img'
          }
        }
      ]
    }
  }
}
</script>
<style
    lang="scss"
    scoped
    src="./team.scss"
/>
