<template>
  <div class="wrapper-base">
    <div class="anchor-target" id="partners-block">
      <div class="title title-large text-center title_mobile-large">
        Почему мы
      </div>
      <div class="line-md title-sm text-center color_dark">
        Нам доверяют лидеры е-com компаний
      </div>
      <div class="cols-clear line-global line_mobile-large">
        <div class="cols partners">
          <div
              v-for="partner of partners"
              :key="partner.imgName"
              class="partners-item"
          >
            <img
                class="partners__img"
                :src="getImgUrl(`partners/${partner.imgName}.svg`)"
                :alt="partner.title"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'partners',

  data () {
    return {
      partners: [
        {title: 'Ozon', imgName: 'ozon'},
        {title: 'Петрович', imgName: 'petrovich'},
        {title: 'СберМегаМаркет', imgName: 'sber'},
        {title: 'Farfetch', imgName: 'farfetch'},
        {title: 'Лента', imgName: 'lenta'},
        {title: 'Ашан', imgName: 'auchan'},
        {title: 'Эльдорадо', imgName: 'eldorado'},
        {title: 'Ситилинк', imgName: 'citylink'},
        {title: 'Все инструменты', imgName: 'vse_instrumenti'},
      ]
    }
  },

  methods: {
    getImgUrl (path) {
      return path ? require(`@/assets/img/landing/${path}`) : ''
    }
  }
}
</script>

<style
    scoped
    lang="scss"
    src="../style.scss"
/>
